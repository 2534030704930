import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Container from "react-bootstrap/esm/Container";
import SlidesBackground from "../components/slidesBackground";
import Icons from "../components/icons";
import AboutCHC from "../components/aboutCHC";
import { Col, Row } from "react-bootstrap";
import Slides from "../components/slides";
import Videos from "../components/videos";
import Specs from "../components/specs";
import Seasons from "../components/seasons";

function About(){
    return (
    <div>
        <Topbar />
        <Navbar />
        <section >
            <SlidesBackground />     
        </section>
            <Container>
                <div>
                    <img className="about-image" style={{width:"40%"}}src="/images/logo_chcnav.png" alt="Logo CHCNav"/>
                </div>
                <div>
                    <h1 className="center">Nawigacja NX510 SE</h1>
                </div>
                <div>
                    <Icons />
                    <hr className="divide" />
                </div>
            </Container>
            <Container>
                <Seasons />
            </Container>
            <br /><br />
            <Container>
                <AboutCHC />
            </Container>
            <Container>
            <Row>
                <Col>
                    <Videos />
                </Col>
            </Row>
             
            </Container>
            <Container>
                <div>
                    <Slides />
                </div>
                <hr className="divide" />
            </Container>
            <Container>
                <Specs />
            </Container>
        <Footer />
    </div>
       
    )
}

export default About;