import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Navigation() {
    return (
        <div className='navbarContainer'>
        <Container>
            <Row>
              {['lg'].map((expand) => (
                <Navbar key={expand} bg="" expand={expand} className="flex">
                    <Container fluid className='flex'>
                          <Navbar.Brand href="/"><img className='harvester' src="/images/logo_harvesternewprecisioncultivation.png" alt="Harvester Logo" />
                          </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                      <img className='harvester' src="/images/logo_harvesternewprecisioncultivation.png" alt="Harvester Logo" />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link href="/">NAWIGACJE ROLNICZE</Nav.Link>
                                    <Nav.Link href="/aktualnosci">AKTUALNOŚCI</Nav.Link>
                                    <Nav.Link href="/oferta">OFERTA MASZYN ROLNICZYCH</Nav.Link>
                                    <Nav.Link href="/kontakt">KONTAKT</Nav.Link>
                                    <Nav.Link href="/maszyny">MASZYNY DOSTĘPNE OD RĘKI</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}   
            </Row>
           
        </Container>
            <hr className="divide"></hr>
        </div>
    );
}

export default Navigation;