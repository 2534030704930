import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/about";
import Kontakt from "./pages/contact";
import NoPage from "./pages/noPage";
import Construction from "./pages/construction";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="aktualnosci" element={<Construction />} />
        <Route path="oferta" element={<Construction />} />
        <Route path="kontakt" element={<Kontakt />} />
        <Route path="maszyny" element={<Construction />} />
        <Route path="*" element={<NoPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
