import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Seasons = () => {
    return (
        <div>
            <Row className='seasons'>
            <h1 className='center divide'>Zapewniamy Ci wsparcie przez wszystkie pory roku!</h1>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img style={{ width: "100%" }} src="/images/seasons/harrowing.jpg" alt="Przykładowe zdjęcie" />
                        <div style={{ position: "absolute", left: "50%", transform: "translate(-50%)", bottom: "20px", fontSize:"x-large", fontWeight:"700" }}>UPRAWA</div>
                    </Container>
                    <br />
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img style={{ width: "100%" }} src="/images/seasons/seeding.jpg" alt="Przykładowe zdjęcie" />
                        <div style={{ position: "absolute", left: "50%", transform: "translate(-50%)", bottom: "20px", fontSize:"x-large", fontWeight:"700" }}>SIEW</div>
                    </Container>
                    <br />
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img style={{ width: "100%" }} src="/images/seasons/spraying2.jpg" alt="Przykładowe zdjęcie" />
                        <div style={{ position: "absolute", left: "50%", transform: "translate(-50%)", bottom: "20px", fontSize:"x-large", fontWeight:"700" }}>OPRYSKIWANIE</div>
                    </Container>
                    <br />
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img style={{ width: "100%" }} src="/images/seasons/harvesting.jpg" alt="Przykładowe zdjęcie" />
                        <div style={{ position: "absolute", left: "50%", transform: "translate(-50%)", bottom: "20px", fontSize:"x-large", fontWeight:"700" }}>ŻNIWA</div>
                    </Container>
                    <br />
                </Col>
            </Row>
        </div>
    );
}

export default Seasons;
