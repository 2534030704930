import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";


function Footer(){
    const date = new Date();
    const year = date.getFullYear()
    return(
        <>
            <div className="footer flex">
                <Container>
                    <Row className="footer-content">
                        <Col xs={12} sm={9}>
                            <Row>
                                <Col sm={4}>
                                    <div>
                                        <div>
                                            <h5>GODZINY OTWARCIA</h5>
                                        </div>
                                        <div>
                                            <div>
                                                <p>
                                                    Pon-Pt............... 07:00 - 16:00
                                                    <br />
                                                    Weekend............... Nieczynne
                                                </p>
                                                <br />
                                                <p>
                                                    SERWIS:
                                                    <br />
                                                    Pon-Pt............... 07:00 - 22:00
                                                    <br />
                                                    Weekend............... Nieczynne
                                                </p>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div>
                                        <div>
                                            <h5>ADRES</h5>
                                        </div>
                                        <div>
                                            <p>
                                                Ul.Kolejowa 6
                                                <br />
                                                63-308 Gizałki
                                            </p>
                                            <br/>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div>
                                        <div>
                                            <h5>DANE FIRMY</h5>
                                        </div>
                                        <div>
                                            <p>
                                                Harvester Marcin Dyoniziak
                                                <br />
                                                Niniew 40
                                                <br />
                                                63-313 Chocz
                                                <br />
                                                NIP 6172027301
                                            </p>
                                            <br/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div>
                                <div>
                                    <h5>KONTAKT</h5>
                                </div>
                                <div>
                                    <p>
                                        TELEFON:
                                        <br />
                                        +48 887 979 450
                                        <br />
                                        +48 662 756 446
                                    </p>
                                    <br />
                                    <p>
                                        EMAIL:
                                        <br />
                                        <a className="email" href="mailto:biuroharvester@gmail.com">biuroharvester@gmail.com</a>
                                        <br />
                                        <a className="email" href="mailto:harvester.dyoniziak@gmail.com">harvester.dyoniziak@gmail.com</a>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> 
            <div className="copyright">
                <Container>
                    <h6>© Harvester {year}</h6>
                </Container>
            </div>  
        </>
    
    )
}

export default Footer
