import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Container from "react-bootstrap/esm/Container";

function NoPage() {
    return (
        <div>
            <Topbar />
            <Navbar />
            <Container>
                <div class="about">
                    <h1>Błąd 404!</h1>
                    <h1>Podana strona nie istnieje!</h1>
                </div>
            </Container>
            <Footer />
        </div>

    )
}

export default NoPage;