import React from 'react';
import { Col, Row } from 'react-bootstrap';

const AboutCHC = () => {
    return (
        <div>
            <Row>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <div>
                        <h2>
                            Dokładność 2,5 cm
                        </h2>
                        <hr />
                    </div>
                    <div>
                        <h5>
                            Absolutna dokładność w wymagającym środowisku i trudnych terenach
                        </h5>
                        <br />
                    </div>
                    <div>
                        <p>NX510 SE to zaawansowany kontroler nawigacyjny zasilany przez wiele źródeł korekcyjnych i pięć konstelacji satelitarnych: GPS, GLONASS, Galileo, Beidou i QZSS. Jego wbudowany modem 4G i UHF łączy się bezproblemowo z wszystkimi standardowymi systemami korekcyjnymi DGPS i RTK, zapewniając sterowanie z dokładnością na poziomie centymetra. Dodatkowo, technologia kompensacji terenu utrzymuje wysoką dokładność nawet w trudnych środowiskach i terenach.</p>
                    </div>
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <img style={{ width: '100%' }} src="/images/CHCSet.png" alt="Obudowa nawigacji CHC" />
                </Col>
            </Row>
            <hr />
            <Row className='chcFlex'>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <img style={{ width: '100%' }} src="/images/CHCWyswietlacz.png" alt="Wyświetlacz nawigacji CHC" />
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <div>
                        <h2>
                            Responsywny, intuicyjny i wytrzymały wyświetlacz
                        </h2>
                        <hr />
                    </div>
                    <div>
                        <h5>
                            Intuicyjne oprogramowanie AgNav, pozwalające na kontrolę wszystkich działań za pomocą dotykowego panelu
                        </h5>
                        <br />
                    </div>
                    <div>
                        <p>AgNav to wielojęzyczne oprogramowanie, działające na 10,1-calowym przemysłowym wyświetlaczu, które obsługuje wiele wzorców prowadzenia, w tym linie AB, linie A+, linie okrężne, nieregularne krzywe i skręt na końcu pola, umożliwiając obsługę wszystkich powszechnych operacji rolniczych. Intuicyjne oprogramowanie AgNav poprawia doświadczenie użytkownika na każdym etapie, umożliwiając przeprowadzanie operacji polowych z dużą prędkością i precyzją.</p>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <div>
                        <h2>
                            Wytrzymała i niezawodna konstrukcja
                        </h2>
                        <hr />
                    </div>
                    <div>
                        <h5>
                            Rozszerzona trwałość w ciężkich, rolniczych warunkach
                        </h5>
                        <br />
                    </div>
                    <div>
                        <p>Przemysłowa konstrukcja NX510 SE uwzględnia elementy odporne na kurz i wodoodporne (klasyfikacja IP65 lub wyższa), doskonale nadające się do użytku w środowisku rolniczym. Dodatkowo, wytrzymałe, o dużej mocy elektryczne koło kierownicy zapewnia szybkie i niezawodne sterowanie.</p>
                    </div>
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <img style={{ width: '100%' }} src="/images/CHCWheel.png" alt="Kierownica CHC" />
                </Col>
            </Row>
            <hr />
            <Row className='chcFlex'>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <img style={{ width: '100%' }} src="/images/CHCBody.png" alt="CHCNav" />
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <div>
                        <h2>
                            Udoskonalony kontroler kierownicy zwiększonej łączności
                        </h2>
                        <hr />
                    </div>
                    <div>
                        <h5>
                            Pracuj z ulubionymi źródłami korekcji RTK poprzez sieci 4G RTK lub stacje UHF RTK.
                        </h5>
                        <br />
                    </div>
                    <div>
                        <p>NX510 SE jest zautomatyzowanym systemem sterowania, możliwym do zamontowania w mniej niż 40 minut na większości maszyn rolniczych znacznie eliminując kosztowne przestoje w pracy. System auto-kierowania posiada ulepszoną wbudowaną łączność, w tym wiele portów CAN, porty szeregowe, Wi-Fi, Bluetooth 4.1, modem 4G oraz nawet wbudowany modem radiowy UHF. Kompatybilność z protokołem ISOBUS dla wszystkich głównych marek sprzętu zapewnia optymalną odpowiedź na wymagające zastosowania rolnicze oraz poprawia operacje polowe.</p>
                    </div>
                </Col>
                
            </Row>
            <hr />
            <Row>
                <Col lg={12}>
                    <div>
                        <h2>
                            Podsumowanie
                        </h2>
                        <hr />
                    </div>
                    <div>
                        <p>
                            NX510 SE to zautomatyzowany system kierowania, który łatwo dostosowuje się do wielu rodzajów nowych i starych traktorów rolniczych oraz innych pojazdów, zapewniając kompaktowe, aktualne i kompleksowe rozwiązanie, które każde gospodarstwo może sobie pozwolić. Zapewnia znaczne wzrosty produktywności, działa we wszystkich warunkach widoczności i zmniejsza zmęczenie operatora.<br />
                            <br />
                            NX510 SE wykorzystuje najnowszą generację zintegrowanego kontrolera nawigacji, który jest łatwiejszy w instalacji, aby nie marnować cennego czasu na polu. Jego ulepszone moduły wbudowane zapewniają łączność, w tym modem 4G i dodatkowy modem radiowy UHF, umożliwiając pracę z ulubionymi źródłami korekcji RTK z lokalnych sieci RTK lub bazowych stacji RTK GNSS. Ponadto, może być łatwo przenoszony z jednego traktora na drugi, oferując niezrównaną elastyczność. Kontroler nawigacji, który obsługuje pełne konstelacje GNSS, oferuje zwiększoną dokładność dla optymalnej niezawodności nawigacji.<br/>
                            <br />
                            Zintegrowana technologia kompensacji terenu GNSS+INS zapewnia dokładność prowadzenia bez użycia rąk z dokładnością do centymetra (lub mniej niż cala) i zapewnia doskonałe rezultaty w aplikacjach związanych z kopaniem rowów, sadzeniem i żniwami.</p>
                    </div>
                </Col>
            </Row>
            <hr />
        </div>
    );
}

export default AboutCHC;
