import React from 'react';

const Videos = () => {
    return (
        <div>
            <h2 style={{ textAlign: "center", marginBottom: "3%" }}>Zobacz naszą nawigację w akcji!</h2>
            <iframe width="100%" height="700rem" src="https://www.youtube.com/embed/w0IyZq9fToU" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <hr className="divide" />
            {/* <iframe width="100%" height="700rem" src="https://www.youtube.com/embed/hriwHfUeCKA?si=Z3X1CL2UsQbRvSCi" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <hr className="divide" /> */}
        </div>
    );
}

export default Videos;
