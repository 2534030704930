import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Footer from "../components/footer";


function Construction() {
    return (
        <>
            <div>
                <Topbar />
                <Navbar />
                <div class="contact">
                    <Container>
                        <Row>
                            <h2 className="center">Strona w trakcie budowy!</h2>
                            <br/>
                            <h5 className="center">Zapraszamy Wkrótce!</h5>
                            <hr className="divide" />
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Construction;