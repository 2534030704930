import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Specs = () => {
    return (
        <div className='specs'>
            <h2 className="center">Specyfikacje sprzętowe</h2>
            <Row>
                <h5 className="center">Dokładność pozycjonowania</h5>
                <Col>Kinematyka w czasie rzeczywistym (RTK)</Col>
                <Col>
                    <p>Pozioma: 8 mm + 1 ppm RMS</p>
                    <p>Pionowa: 15 mm + 1 ppm RMS</p>
                    <p>Czas inicjacji: {"<"} 5 sek</p>
                    <p>Niezawodność inicjacji: {">"} 99.9%</p>
                </Col>
            </Row>
            <Row>
                <Col>Dokładność prędkości</Col>
                <Col>
                    <p>0.03 m/s RMS</p>
                </Col>
            </Row>
            <Row>
                <h5 className="center">Wydajność</h5>
                <Col>Dokładność od przejścia do przejścia</Col>
                <Col>
                    <p>≤ ±2.5 cm </p>
                </Col>
            </Row>
            <Row>
                <h5 className="center">Parametry fizyczne</h5>
                <Col>Moc zewnętrzna</Col>
                <Col>
                    <p>9 V DC do 32 V DC</p>
                </Col>
            </Row>
            <Row>
                <Col>Środowisko</Col>
                <Col>
                    <p>Praca 20°C ~ +70°C</p>
                    <p>Przechowywanie -40°C ~ +85°C</p>
                </Col>
            </Row>
            <Row>
                <h5 className="center">Kierownica elektryczna</h5>
                <Col>Zasilanie</Col>
                <Col>
                    <p>9 V DC do 32 V DC</p>
                </Col>
            </Row>
            <Row>
                <Col>Moment obrotowy</Col>
                <Col><p>7,5 N*m</p></Col>
            </Row>
            <Row>
                <Col>Średnica Koła</Col>
                <Col><p>410 mm</p></Col>
            </Row>
            <Row>
                <Col>Wysokość sillnika</Col>
                <Col><p>80,5 mm</p></Col>
            </Row>
            <Row>
                <h5 className="center">Wyświetlacz</h5>
                <Col>Wyświetlacz</Col>
                <Col>
                    <p>Ekran dotykowy 10.1"</p>
                    <p>281 mm x 181 mm x 42 mm</p>
                    <p>Android 6.01</p>
                    <p>Pyłoodporność i wodoodporność: IP65</p>
                </Col>
            </Row>
            <Row>
                <h5 className="center">Kamera Tylna</h5>
                <Col>Piksele</Col>
                <Col><p>1280 x 720 pikseli</p></Col>
            </Row>
            <Row>
                <Col>Kąt widzenia kamery</Col>
                <Col><p>120°</p></Col>
            </Row>
            <Row>
                <h5 className="center">Odbiornik</h5>
                <Col>Rozmiar</Col>
                <Col><p>220 mm x 205 mm x 60mm</p></Col>
            </Row>
            <Row>
                <Col>Waga</Col>
                <Col><p>{"<"} 2 kg</p></Col>
            </Row>
            <Row>
                <Col>Moc</Col>
                <Col><p>9 V DC do 36 V DC</p></Col>
            </Row>
            <Row>
                <Col>Pyłoodponość i wodoodporność</Col>
                <Col><p>IP67</p></Col>
            </Row>
            <Row>
                <h5 className="center">Systemy Nawigacyjne</h5>
                <Col>GPS</Col>
                <Col><p>L1/L2/L5</p></Col>
            </Row>
            <Row>
                <Col>BDS</Col>
                <Col><p>B1I/B2I/B3I/B1C/B2a/B2b</p></Col>
            </Row>
            <Row>
                <Col>Galileo</Col>
                <Col><p>E1/E5a/E5b/E6</p></Col>
            </Row>
            <Row>
                <Col>GLONASS</Col>
                <Col><p>L1/L2</p></Col>
            </Row>
            <Row>
                <Col>SBAS</Col>
                <Col><p>L1</p></Col>
            </Row>
            <Row>
                <Col>QZSS</Col>
                <Col><p>L1/L2/L5/L6</p></Col>
            </Row>
            <Row>
                <h5 className="center">Komunikacja</h5>
                <Col>WiFi/Bluetooth</Col>
                <Col><p>Tak</p></Col>
            </Row>
            <Row>
                <Col>Post Szeregowy</Col>
                <Col><p>RS232 x 2</p></Col>
            </Row>
            <Row>
                <Col>Porty CAN</Col>
                <Col><p>2</p></Col>
            </Row>
            <Row>
                <Col>Wyjście NIMEA</Col>
                <Col><p>1/2/5/10 Hz</p></Col>
            </Row>
            <Row>
                <Col>Formaty korekcji</Col>
                <Col><p>RTCM 3.0/3.1/3.2/3.3</p></Col>
            </Row>
            <Row>
                <Col>Modem sieci 4G</Col>
                <Col><p>Zintegrowany w odbiorniku i wyświetlaczu</p></Col>
            </Row>
            <Row>
                <Col>Moduł UHF</Col>
                <Col>
                    <p>Częstotliwość 410-470 MHz</p>
                    <p>Protokół: TT450S/Transparent/CHC</p>
                </Col>
            </Row>
            <Row>
                <Col>Interfejs Wyjściowy</Col>
                <Col>
                    <p>3 x LED (Zasilanie, satelity, korekcje RTK)</p>
                    <p>1 x Złącze anteny UHF</p>
                </Col>
            </Row>
        </div>
    );
}

export default Specs;
