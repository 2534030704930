import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const Slides = () => {
    return (
        <div>
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100 opacity"
                    src="/images/slides/1.jpg"
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 opacity"
                    src="/images/slides/2.jpg"
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 opacity"
                    src="/images/slides/3.jpg"
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 opacity"
                    src="/images/slides/4.jpg"
                    alt="Fourth slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 opacity"
                    src="/images/slides/5.jpg"
                    alt="Fifth slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 opacity"
                    src="/images/Santander2.jpg"
                    alt="Sixth slide"
                />
            </Carousel.Item>
        </Carousel>
        </div>
    );
}

export default Slides;
