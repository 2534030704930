import React from 'react';
import { ArrowsFullscreen, CashStack, Check2Square, Stars, Translate, Wifi, WrenchAdjustableCircle } from "react-bootstrap-icons";
import { Col, Row } from 'react-bootstrap';

const Icons = () => {
    return (
        <div className='icons'>
        <Row>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <Check2Square size={65} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Dokładność 2,5 cm</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <ArrowsFullscreen size={65} color={'gray'} />
                <h5 style={{ color: 'gray' }}>10,1 Calowy Wyświetlacz</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <Translate size={65} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Wielojęzyczne Oprogramowanie</h5>
            </Col>                               
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <Stars size={65} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Wysokiej Jakości Komponenty</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <img src='/images/tractor_line.png' alt='Ikona linii nawigacji' width={"100px"} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Obsługa Wielu Wzorów Nawigacji</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <img src='/images/farm_grid.png' alt='Ikona farmy' width={"100px"} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Zarządzanie Gospodarstwem/ Mapowanie pól</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <img src="/images/uturn.png" alt='Ikona automatycznego zawracania' width={"100px"} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Automatyczne zawracanie</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <img src="/images/Isobus_Terminal.png" alt='Ikona ISOBUS' width={"100px"} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Kontrola ISOBUS</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <WrenchAdjustableCircle size={65} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Montaż Oraz Serwis gwarancyjny i pogwarancyjny</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <img src="/images/tractor_terrain.png" alt='Ikona kompensacji terenu' width={"100px"} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Kompensacja terenu</h5>
            </Col>
             <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <CashStack size={65} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Finansowanie fabryczne 0%</h5>
            </Col>
            <Col className="icon" lg={3} md={6} sm={6} xs={6} >
                <Wifi size={65} color={'gray'} />
                <h5 style={{ color: 'gray' }}>Abonament RTK Gratis!</h5>
            </Col>
        </Row>         
        </div>
    );
}

export default Icons;
