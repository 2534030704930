import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Facebook, Youtube } from "react-bootstrap-icons";

function Topbar(){
return (
    <div className="flex topbar">
        <Container className="">
            <Row>
                <Col xs={12} sm={12} md={12} lg={7} className="topbar-part text-lg-left text-center">
                    <strong>Autoryzowany dealer</strong>
                    {/* <img className="miniLogo" src="/images/logo-landtechnik.png" alt="Landtechnik Logo" />
                    <img className="miniLogo" src="/images/logo-tolmet.png" alt="Tolmet Logo" /> */}
                    <a href='https://chcnav.com/index'><img className="chcLogo" src="/images/chcnav-logo-white.png" alt="Chcnav Logo" /></a>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                    <div className="topbar-right text-lg-right text-center">
                        <p><strong>Znajdź nas na:</strong>
                            <a href="https://www.youtube.com/@harvestermarcindyoniziak"><Youtube  style={{margin:"2% 3%"}}size={40} color="red" /></a>
                            <a href="https://www.facebook.com/profile.php?id=100083475339356"><Facebook size={40} /></a>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>    
); 
}


export default Topbar;