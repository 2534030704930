import React from 'react';
import { Carousel, Container, Row } from 'react-bootstrap';

function SlidesBackground() {
    return (
        <Container>
            <Row>
                <Carousel>
                    {/* <Carousel.Item>
                        <img
                            className="d-block w-100 opacity"
                            src="/images/Rolnictwo4.0.png"
                            alt="First slide"
                        />
                    </Carousel.Item> */}
                    <Carousel.Item>
                        <img
                            className="d-block w-100 opacity"
                            src="/images/traktor_navi_kolka.png"
                            alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>        
            </Row>
        </Container>
        
    );
}

export default SlidesBackground;